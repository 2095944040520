export default class IsScrolling {
	constructor() {
		this.body = document.querySelector('body')

		this.watchScroll = this.watchScroll.bind(this)
	}

	init() {
		this.fixHeader()
		this.addEvents()
	}

	addEvents() {
		window.addEventListener('scroll', this.watchScroll)
	}

	watchScroll() {
		this.fixHeader()
	}

	fixHeader() {
		this.body.classList[document.documentElement.scrollTop > 40 ? 'add' : 'remove']('fixHeader')
	}
}
