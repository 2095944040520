export default class Hamburger {
	constructor() {
		this.hamburger = document.querySelector('.hamburger')
		this.navbar = document.querySelector('.navbar')
		this.overlay = document.querySelector('.header-wrapperOverlay')
		this.body = document.querySelector('body')

		this.toggleActive = this.toggleActive.bind(this)
	}

	init() {
		this.addEvents()
	}

	addEvents() {
		this.hamburger.addEventListener('click', this.toggleActive)
	}

	toggleActive() {
		this.hamburger.classList.toggle('active')
		this.navbar.classList.toggle('active')
		this.overlay.classList.toggle('active')

		if (this.overlay.classList.contains('active')) {
			this.overlay.addEventListener('click', this.toggleActive)
			this.body.classList.add('overflow-y')
		} else {
			this.overlay.removeEventListener('click', this.toggleActive)
			this.body.classList.remove('overflow-y')
		}
	}
}
