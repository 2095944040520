export default class Navbar {
	constructor() {
		this.navbar = document.querySelector('.navbar')
		this.headerOverlay = document.querySelector('.header-wrapperOverlay')
		this.submenuButton = [...this.navbar.querySelectorAll('.submenu-btn')]
		this.mql = window.matchMedia('(max-width: 1024px)')
		this.hamburger = document.querySelector('.hamburger')

		this.toggleSubmenu = this.toggleSubmenu.bind(this)
		this.touchstartX = 0
		this.touchendX = 0
	}

	init() {
		this.addEvents()
		this.screenSizeWatch()
	}

	addEvents() {
		this.submenuButton.forEach((submenu) => {
			submenu.addEventListener('click', this.toggleSubmenu)
		})
	}

	toggleSubmenu(event) {
		const target = event.target
		const submenu = target.parentNode.querySelector('.submenu-list')
		if (this.mql.matches) {
			target.classList.toggle('opened')
			submenu.classList.toggle('opened')
			submenu.style.maxHeight = submenu.style.maxHeight
				? submenu.removeAttribute('style')
				: `${submenu.scrollHeight}px`
		}
	}

	screenSizeWatch() {
		this.mql.onchange = (e) => {
			const isMobile = !!e.matches

			if (!isMobile) {
				;[...this.navbar.querySelectorAll('.opened')].forEach((item) => {
					item.classList.remove('opened')
				})
				;[...this.navbar.querySelectorAll('.submenu-list')].forEach((item) => {
					item.removeAttribute('style')
				})
			}
		}
	}
}
